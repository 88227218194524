import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './styles.scss';
import Applications from 'containers/AccessManagement/Applications/Applications';

class SideNav extends React.Component {
  static propTypes = {
    content: PropTypes.array.isRequired,
    small: PropTypes.bool,
  };

  render() {
    const { content, small } = this.props;

    const item = [
      <nav key="nav" className={clsx('sidebar', { sidebarSmall: small })} role="navigation">
        <Applications applications={content} small={small} />
      </nav>,
    ];

    return item;
  }
}

export default SideNav;
