import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute';
import NotFound from 'containers/NotFound';
import SignIn from 'containers/SignIn';
import SignOut from 'containers/SignOut';
import AccessManagement from 'containers/AccessManagement/Loadable';

import ROUTES from 'utils/routes';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={ROUTES.SIGN_IN} component={SignIn} />
        <PrivateRoute exact path={ROUTES.SIGN_OUT} component={SignOut} />
        <PrivateRoute exact path={ROUTES.ACCESS_MANAGEMENT} component={AccessManagement} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
