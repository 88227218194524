import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useDispatcherApplication } from 'providers/AccessManagementApplication/useDispatcher';
import { useSelectorApplication } from 'providers/AccessManagementApplication/useSelectors';

import Navbar from 'components/Navbar';
import SideNav from 'components/SideNav';

import ROUTES from 'utils/routes';
import './styles.scss';

export function Layout({ children }) {
  const [small, setSmall] = useState(false);
  const { getApplications, selectedApplication } = useDispatcherApplication();
  const { applicationsState } = useSelectorApplication();

  useEffect(() => {
    getApplications();
  }, [getApplications]);

  useEffect(() => {
    if (applicationsState.loaded && applicationsState.list.length) {
      selectedApplication(applicationsState.list[0]);
    }
  }, [applicationsState.loaded, applicationsState.list, selectedApplication]);

  const renderContent = () => {
    let totalApps = [];
    if (applicationsState.loaded && applicationsState.list.length) {
      totalApps = applicationsState.list.map(app => ({
        to: ROUTES.ACCESS_MANAGEMENT,
        application: app.application,
        id: app.id,
        hasPermission: true,
      }));

      return <SideNav key="sideNav" small={small} content={totalApps} />;
    } else {
      return <SideNav key="sideNav" small={small} content={totalApps} />;
    }
  };

  return (
    <>
      <Navbar title="Role-Based Permissions" onSmall={() => setSmall(!small)} />
      <div className="app-container">
        {renderContent()}

        <div className={clsx('contentPage', { contentPageSmall: small })}>
          <main className="main-container">{children}</main>
        </div>
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
