import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import styles from './styles.module.scss';

function Backdrop({ variant, ...others }) {
  return <div className={clsx(styles.root, { [styles[variant]]: variant === 'transparent' })} {...others} />;
}

Backdrop.propTypes = {
  variant: PropTypes.oneOf(['transparent']),
};

export default Backdrop;
