import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@gbm/queen-ui-guidelines';

import { onIdleTimeout, isSessionExpired, WARNING_TIME_S, setActive, shouldWarn } from 'utils/auth';
function SessionTimeoutDetector({ onContinue, onIdleSessionExpired }) {
  const [displayWarning, setDisplayWarning] = useState(false);

  const checkIdleTimeout = useCallback(() => {
    if (onIdleTimeout() && !displayWarning && !shouldWarn()) {
      setDisplayWarning(true);
    }

    if (isSessionExpired()) {
      onIdleSessionExpired();
    }
  }, [displayWarning, onIdleSessionExpired]);

  useEffect(() => {
    let interval = setInterval(checkIdleTimeout, WARNING_TIME_S);

    return () => clearInterval(interval);
  }, [checkIdleTimeout]);

  const continueSession = () => {
    setActive();
    onContinue();
    setDisplayWarning(false);
  };

  return (
    <Dialog id="dialogSessionTimeoutDetector" data-testid="dialogSessionTimeoutDetector" open={displayWarning}>
      <DialogTitle>The session will be finished in less than 5 minutes.</DialogTitle>

      <DialogContent>Do you want to extend the time of the session?</DialogContent>
      <DialogActions>
        <Button
          id="btnCancelSessionTimeoutDetector"
          data-testid="btnCancelSessionTimeoutDetector"
          color="light"
          onClick={() => {
            setDisplayWarning(false);
            setActive();
          }}>
          Cancel
        </Button>

        <Button
          id="btnContinueSessionTimeoutDetector"
          data-testid="btnContinueSessionTimeoutDetector"
          onClick={continueSession}>
          Continue in session
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SessionTimeoutDetector.propTypes = {
  onContinue: PropTypes.func,
  onIdleSessionExpired: PropTypes.func,
};

export default SessionTimeoutDetector;
