import { onlyLettersRegex, nameRegex, nameRegexWithDot, emailRegex } from 'utils/validations';
import { removeMultipleSpaces, removeAccents, removeSpacesValueField, lowerCaseValueField } from 'utils/normalize';

export const roleNameInput = {
  id: 'inputRoleName',
  name: 'roleName',
  label: 'Name',
  placeholder: "Enter role's name",
  required: true,
  maxLength: 60,
  register: {
    required: "Role's name is required",
    pattern: {
      value: onlyLettersRegex,
      message: 'Numbers or special chars are not allowed.',
    },
  },
};

export const descriptionInput = {
  id: 'inputDescription',
  name: 'description',
  label: 'Description',
  placeholder: "Enter role's description",
  required: true,
  maxLength: 150,
  register: {
    required: 'Description is required.',
  },
};

export const isNameUsed = (value, rolesList, roleToEdit) => {
  const newRoleName = removeMultipleSpaces(value);

  return (
    newRoleName &&
    rolesList.some(
      role =>
        removeAccents(role.displayName?.toLowerCase()) === removeAccents(newRoleName?.toLowerCase()) &&
        role.id !== roleToEdit?.id
    )
  );
};

export const sectionNameInput = {
  id: 'sectionNameInput',
  name: 'sectionName',
  label: 'Section',
  placeholder: "Enter section's name ",
  required: true,
  maxLength: 60,
  register: {
    required: "Section's name is required.",
    pattern: {
      value: nameRegex,
      message: 'Numbers or special chars are not allowed.',
    },
  },
};

export const isSectionUsed = (value, sectionsList) => {
  const newName = removeMultipleSpaces(value);

  return newName && sectionsList.some(item => item.section.toLowerCase() === newName.toLowerCase());
};

export const permissionNameInput = {
  id: 'permissionNameInput',
  name: 'permissionName',
  label: 'Permission',
  placeholder: "Enter permission's name",
  required: true,
  maxLength: 120,
  register: {
    required: "Permission's name is required.",
    pattern: {
      value: nameRegexWithDot,
      message: 'Numbers or special chars are not allowed.',
    },
  },
};

export const permissionDescriptionInput = {
  id: 'permissionDescriptionInput',
  name: 'permissionDescription',
  label: 'Description',
  placeholder: "Enter permission's description",
  required: true,
  maxLength: 120,
  register: {
    required: 'Description is required.',
  },
};

export const inputUrl = {
  name: 'inputUrl',
  placeholder: 'GET /api/v1/example/*/{valueId}',
  maxLength: 120,
};

export const isPermissionUsed = (value, sectionsList) => {
  const newName = removeMultipleSpaces(value);

  return (
    newName &&
    sectionsList.some(section =>
      section.permissions.some(item => item.permission.toLowerCase() === newName.toLowerCase())
    )
  );
};

export const filterUserRoleOptions = {
  employeeNumber: {
    name: 'employeeNumber',
    value: 'Employee number',
  },
  email: {
    name: 'email',
    value: 'Email',
  },
};

const inputSearchUserRole = {
  id: 'inputSearchUserRole',
  name: 'employeeNumber',
  placeholder: "Enter employee's number",
  required: true,
  register: {},
  onChange: removeSpacesValueField,
};

const inputEmailUserRole = {
  id: 'inputEmailUserRole',
  name: 'email',
  placeholder: 'Enter email',
  required: true,
  onChange: lowerCaseValueField,
  register: {
    pattern: {
      value: emailRegex,
      message: 'Enter a valid email',
    },
  },
};

export const userRoleFormDefinition = {
  [filterUserRoleOptions.employeeNumber.name]: inputSearchUserRole,
  [filterUserRoleOptions.email.name]: inputEmailUserRole,
};

const employeeNumberFilter = {
  name: filterUserRoleOptions.employeeNumber.name,
  label: 'Employee number',
  icon: 'remixicon-profile-line',
};

const emailFilter = {
  name: filterUserRoleOptions.email.name,
  label: 'Email',
  icon: 'remixicon-at-line',
};

export const filterUserRoleDefinition = [employeeNumberFilter, emailFilter];

export const REASON_TO_EDIT_USER_ROLE = 'Proceso del aplicativo RBP para la editar el rol de un usuario';

export function sortRolesByName(roleList) {
  return roleList.sort((a, b) => a.role.localeCompare(b.role));
}

export const applicationNameInput = {
  id: 'applicationNameInput',
  name: 'applicationName',
  label: 'Application',
  placeholder: "Enter application's name ",
  required: true,
  maxLength: 60,
  register: {
    required: "Application's name is required.",
    pattern: {
      value: nameRegex,
      message: 'Numbers or special chars are not allowed.',
    },
  },
};

export const isApplicationUsed = (value, applicationsList) => {
  const newName = removeMultipleSpaces(value);

  return newName && applicationsList.some(item => item.application.toLowerCase() === newName.toLowerCase());
};

export function sortAppsByName(appList) {
  return appList.sort((a, b) => a.application.localeCompare(b.application));
}
