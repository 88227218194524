import { useEffect, useCallback } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { redirectToLogin } from 'utils/auth';
import { LOCAL_STORAGE } from 'utils/constants';

export function SignIn() {
  const history = useHistory();
  const location = useLocation();
  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);

  const redirectHome = useCallback(() => {
    if (token) history.replace(`/`);
  }, [history, token]);

  useEffect(() => {
    if (location && !token) {
      if (!location.search.includes('code')) {
        redirectToLogin();
      }

      history.replace(`/`);
    }

    redirectHome();
  }, [history, location, redirectHome, token]);

  return null;
}

export default SignIn;
