import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, DisplayMessage, Icon, Tooltip, Typography } from '@gbm/queen-ui-guidelines';

import IconDelete from 'components/IconDelete';
import { NavLink } from 'react-router-dom';
import { ApplicationModal } from './ApplicationModal';

import styles from './styles.module.scss';
import { ApplicationDeleteDialog } from '../Dialogs/ApplicationDeleteDialog';
import { ApplicationDeleteConfirmation } from '../Dialogs/ApplicationDeleteConfirmation';

import { useDispatcherApplication } from 'providers/AccessManagementApplication/useDispatcher';
import { useSelectorApplication } from 'providers/AccessManagementApplication/useSelectors';

function Applications({ applications }) {
  const [isOpenModalApp, setIsOpenModalApp] = useState(false);
  const [isOpenDialogDeleteApp, setIsOpenDialogDeleteApp] = useState(false);
  const [isOpenConfirmationDeleteApp, setIsOpenConfirmationDeleteApp] = useState(false);
  const [appToEdit, setAppToEdit] = useState(null);
  const [appToDelete, setAppToDelete] = useState(null);
  const { applicationsState, selectedApplicationState } = useSelectorApplication();
  const activeApplicationName = selectedApplicationState.app.application;

  const {
    getApplications,
    createApplication,
    editApplication,
    deleteApplication,
    selectedApplication,
    resetCreateApplication,
    resetEditApplication,
    resetDeleteApplication,
  } = useDispatcherApplication();

  const { createApplicationState, editApplicationState, deleteApplicationState } = useSelectorApplication();

  useEffect(() => {
    return () => {
      resetCreateApplication();
      resetEditApplication();
      resetDeleteApplication();
    };
  }, [resetCreateApplication, resetEditApplication, resetDeleteApplication]);

  const handleOpenModalCreateApp = () => {
    setIsOpenModalApp(true);
    setAppToEdit(null);
    resetEditApplication();
  };

  const handleOpenModalToEditApp = app => {
    setIsOpenModalApp(true);
    setAppToEdit(app);
  };

  const handleCloseModalApp = () => {
    setIsOpenModalApp(false);
  };

  const handleOpenDialogToDeleteApp = app => {
    setAppToDelete(app);
    setIsOpenDialogDeleteApp(true);
  };

  const handleOpenConfirmationDeleteApp = e => {
    e.preventDefault();
    setIsOpenDialogDeleteApp(false);
    setIsOpenConfirmationDeleteApp(true);
  };

  const handleCloseConfirmationDeleteApp = () => {
    setIsOpenConfirmationDeleteApp(false);
  };

  const handleCreateApp = ({ applicationName }) => {
    createApplication({ application: applicationName });
  };

  const handleEditApp = ({ applicationName }) => {
    editApplication({ id: appToEdit.id, application: applicationName });
  };

  const handleAppSelected = app => {
    selectedApplication(app);
  };

  useEffect(() => {
    if (createApplicationState.loaded || editApplicationState.loaded) {
      setIsOpenModalApp(false);
      getApplications();
    }
  }, [createApplicationState.loaded, editApplicationState.loaded, getApplications]);

  useEffect(() => {
    if (deleteApplicationState.loaded) {
      setIsOpenConfirmationDeleteApp(false);
      getApplications();
    }
  }, [deleteApplicationState.loaded, getApplications]);

  const renderListApplications = () => {
    let items = [];
    if (applications.length) {
      applications.map((elem, i) => {
        if (elem.to !== undefined) {
          elem.hasPermission &&
            items.push(
              <li key={i}>
                <NavLink
                  exact
                  to={elem.to}
                  activeClassName={activeApplicationName === elem.application ? 'active' : 'inactive'}>
                  <div onClick={() => handleAppSelected(elem)} className={styles.sidebarLabel}>
                    <div className={styles.content}>{elem.application}</div>
                    <div>
                      <Tooltip message="Edit" position="top">
                        <Button
                          id={'btnEditApp'}
                          data-testid={'btnEditApp'}
                          icon
                          size="xs"
                          color="light"
                          onClick={() => handleOpenModalToEditApp(elem)}>
                          <Icon className="remixicon-pencil-fill" />
                        </Button>
                      </Tooltip>
                      <Tooltip message="Delete" position="top">
                        <Button
                          id={'btnDeleteApp'}
                          data-testid={'btnDeleteApp'}
                          icon
                          size="xs"
                          color="light"
                          onClick={() => handleOpenDialogToDeleteApp(elem)}>
                          <IconDelete />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </NavLink>
              </li>
            );
        }
      });
      return <ul className="nav">{items}</ul>;
    } else {
      return (
        <DisplayMessage id="msgApplicationsEmptyInfo" variant="info" message="There are not applications configured" />
      );
    }
  };

  return (
    <>
      <div className={styles.sidebarTitle}>
        <Typography component="h4">{'Applications'}</Typography>
        <Button bordered={false} color="info" icon size={'xs'} type="button" onClick={handleOpenModalCreateApp}>
          <Icon className="fas fa-plus" component="i" />
        </Button>
      </div>
      {renderListApplications()}

      {isOpenModalApp && (
        <ApplicationModal
          open={isOpenModalApp}
          onClose={handleCloseModalApp}
          title={appToEdit ? 'Edit application' : 'Create application'}
          applications={applicationsState.list}
          appToEdit={appToEdit}
          onSubmit={appToEdit ? handleEditApp : handleCreateApp}
          loading={appToEdit ? editApplicationState.loading : createApplicationState.loading}
          error={Boolean(appToEdit ? editApplicationState.error : createApplicationState.error)}
        />
      )}

      {isOpenDialogDeleteApp && (
        <ApplicationDeleteDialog
          isOpen={isOpenDialogDeleteApp}
          onClose={() => setIsOpenDialogDeleteApp(false)}
          onConfirm={handleOpenConfirmationDeleteApp}
          application={appToDelete}
        />
      )}

      {isOpenConfirmationDeleteApp && (
        <ApplicationDeleteConfirmation
          isOpen={isOpenConfirmationDeleteApp}
          onClose={handleCloseConfirmationDeleteApp}
          onConfirm={() => deleteApplication(appToDelete.id)}
          loading={deleteApplicationState.loading}
          error={deleteApplicationState.error}
          application={appToDelete}
        />
      )}
    </>
  );
}

Applications.propTypes = {
  applications: PropTypes.array,
  onCreateApplication: PropTypes.func,
  onEditApplication: PropTypes.func,
  onDeleteApplication: PropTypes.func,
};

export default Applications;
