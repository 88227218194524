import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { signOut } from 'utils/auth';

function SignOut(props) {
  const { onSignOut } = props;
  useEffect(() => {
    signOut();

  });

  return null;
}

// SignOut.defaultProps = {
//   onSignOut: signOut,
// };

SignOut.propTypes = {
  onSignOut: PropTypes.func
}
export default SignOut;
