import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Grid, Card, CardBody } from '@gbm/queen-ui-guidelines';

import IconSvg from './IconSvg';

export class ViewSignOut extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
    };

    this.timeout = setTimeout(this.setInactive, props.inactiveTimeout);
  }

  componentWillUnmount = () => {
    this.setInactive();
  };

  setInactive = () => {
    const { onSetInactive } = this.props;

    if (this.timeout) {
      this.setState({ open: false });
      clearTimeout(this.timeout);
      delete this.timeout;
      onSetInactive && onSetInactive();
    }
  };

  render() {
    const { noPermissions } = this.props;

    return (
      <Grid container className="mt-4" id="viewSignOut" data-testid="viewSignOut">
        <Grid row justify="center">
          <Grid md={8} lg={6} xl={5}>
            <Card>
              <CardBody className="p-5">
                <div className="text-center">
                  <div className="mt-4">
                    <div className="logout-checkmark">
                      <IconSvg />
                    </div>
                  </div>

                  <Typography component="h3">Logging out</Typography>

                  {noPermissions ? (
                    <Typography component="h5">
                      You do not have permissions to access RBP, please login with another user
                    </Typography>
                  ) : (
                    <Typography variant="textMuted">Please, login again</Typography>
                  )}
                </div>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ViewSignOut.propTypes = {
  inactiveTimeout: PropTypes.number,
  noPermissions: PropTypes.bool,
  onSetInactive: PropTypes.func,
  open: PropTypes.bool,
};

ViewSignOut.defaultProps = {
  inactiveTimeout: 6000,
  noPermissions: false,
  onSetInactive: undefined,
  open: false,
};

export default ViewSignOut;
