import { useContext } from 'react';

import {
  getApplicationsRequest,
  createApplicationRequest,
  editApplicationRequest,
  deleteApplicationRequest,
} from 'utils/request/accessManagement';

import { ApplicationContext } from './index';
import * as actions from './actions';

import { sortAppsByName } from 'utils/accessManagement';

let appDispatch;

async function getApplications() {
  try {
    appDispatch(actions.getApplications.request());

    const { items } = await getApplicationsRequest();

    appDispatch(actions.getApplications.success(sortAppsByName(items)));
  } catch (error) {
    appDispatch(actions.getApplications.failure(error));
  }
}

async function createApplication(values) {
  try {
    appDispatch(actions.createApplication.request());

    await createApplicationRequest(values);

    appDispatch(actions.createApplication.success());
  } catch (error) {
    appDispatch(actions.createApplication.failure(error));
  }
}

async function editApplication(values) {
  try {
    appDispatch(actions.editApplication.request());

    await editApplicationRequest(values);

    appDispatch(actions.editApplication.success());
  } catch (error) {
    appDispatch(actions.editApplication.failure(error));
  }
}

async function deleteApplication(values) {
  try {
    appDispatch(actions.deleteApplication.request());

    await deleteApplicationRequest(values);

    appDispatch(actions.deleteApplication.success());
  } catch (error) {
    appDispatch(actions.deleteApplication.failure(error));
  }
}

async function selectedApplication(app) {
  try {
    appDispatch(actions.selectedApplication.success(app));
  } catch (error) {
    appDispatch(actions.selectedApplication.failure(error));
  }
}

function resetCreateApplication() {
  appDispatch(actions.createApplication.reset());
}

function resetEditApplication() {
  appDispatch(actions.editApplication.reset());
}

function resetDeleteApplication() {
  appDispatch(actions.deleteApplication.reset());
}

function resetSelectedApplication() {
  appDispatch(actions.selectedApplication.reset());
}

export function useDispatcherApplication() {
  const { dispatch } = useContext(ApplicationContext);
  appDispatch = dispatch;

  return {
    getApplications,
    createApplication,
    editApplication,
    deleteApplication,
    selectedApplication,
    resetCreateApplication,
    resetEditApplication,
    resetDeleteApplication,
    resetSelectedApplication,
  };
}
