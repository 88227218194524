import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';

import { Spinner } from '@gbm/queen-ui-guidelines';

import Providers from 'providers';

import { LOCAL_STORAGE } from 'utils/constants';
import ROUTES from 'utils/routes';
import { getPermissions } from 'utils/request/roles';
import {
  getTokenRequest,
  refreshTokenRequest,
  signOut,
  getPermissionsList,
  INACTIVE_TIMEOUT,
  getRoleToken,
} from 'utils/auth';

import Layout from 'components/Layout';
import ViewSignOut from 'components/ViewSignOut';
import SessionTimeoutDetector from 'components/SessionTimeoutDetector';
import Backdrop from 'components/Backdrop';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);

  const [, code] = location.search.split('?code=');

  useEffect(() => {
    async function getToken() {
      if (location.search.includes('code')) {
        try {
          const { idToken, refreshToken } = await getTokenRequest({ code });
          localStorage.setItem(LOCAL_STORAGE.TOKEN, idToken);
          localStorage.setItem(LOCAL_STORAGE.REFRESH_TOKEN, refreshToken);
          const role = await getRoleToken();
          localStorage.setItem(LOCAL_STORAGE.ROLE_ID, role);
          history.replace(`/`);
        } catch (error) {
          setIsLoggedOut(true);
        }
      }
    }

    getToken();
  }, [history, location.search, code]);

  useEffect(() => {
    const loadPermissions = async () => {
      setLoading(true);
      try {
        const { items } = await getPermissions();
        setPermissions(getPermissionsList(items[0].permissions));
      } catch (error) {
        setIsLoggedOut(true);
      } finally {
        setLoading(false);
      }
    };

    if (token && !isLoggedOut) {
      loadPermissions();
    }
  }, [code, isLoggedOut, token]);

  const refreshToken = async () => {
    const { idToken } = await refreshTokenRequest(localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN));
    localStorage.setItem(LOCAL_STORAGE.TOKEN, idToken);
  };

  if (loading) {
    return (
      <Backdrop id="spinnerLoading" data-testid="spinnerLoading">
        <Spinner size="sm" color="info" />
      </Backdrop>
    );
  }

  if (token) {
    return isLoggedOut ? (
      <ViewSignOut noPermissions={!permissions?.length} inactiveTimeout={INACTIVE_TIMEOUT} onSetInactive={signOut} />
    ) : (
      <Route
        {...rest}
        render={props => (
          <Providers features={permissions}>
            <Layout>
              <Component {...props} />
              <SessionTimeoutDetector onIdleSessionExpired={() => setIsLoggedOut(true)} onContinue={refreshToken} />
            </Layout>
          </Providers>
        )}
      />
    );
  } else {
    return location.search.includes('code') ? null : (
      <Route {...rest} render={() => <Redirect to={ROUTES.SIGN_IN} />} />
    );
  }
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

export default PrivateRoute;
