import React from 'react';
import PropTypes from 'prop-types';

function Compose({ components = [], children }) {
  return components.reduceRight((acc, Comp) => <Comp>{acc}</Comp>, children);
}

Compose.propTypes = {
  components: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.func])),
  children: PropTypes.node,
};

export default Compose;
