import { useContext } from 'react';

import { ApplicationContext } from 'providers/AccessManagementApplication';

export function useSelectorApplication() {
  const { state } = useContext(ApplicationContext);

  return {
    applicationsState: state.applications,
    createApplicationState: state.createApplication,
    editApplicationState: state.editApplication,
    deleteApplicationState: state.deleteApplication,
    selectedApplicationState: state.selectedApplication,
  };
}
