/* eslint-disable no-unused-vars */
import request from 'utils/request';
import { getRoleToken } from 'utils/auth';

import { envConfig } from 'config';

export async function getPermissions() {
  const roleId = await getRoleToken();
  return request(`${envConfig.API_BASE}/api/v1/roles/${roleId}`);
}
