import queryString from 'query-string';
import { envConfig } from 'config';
import request from 'utils/request';
import { capitalizeValue, pascalCase } from 'utils/normalize';
import { getCurrentDateUtc } from 'utils/dates';

const API_BASE_URL = `${envConfig.API_BASE}/api`;

export function getRolesRequest(appId, options = {}) {
  return request(`${API_BASE_URL}/v1/roles/application/${appId}`, {
    method: 'GET',
    ...options,
  });
}

export function getSectionsRequest(appId, options = {}) {
  return request(`${API_BASE_URL}/v1/sections/permissions/application/${appId}`, {
    method: 'GET',
    ...options,
  });
}

export function createRoleRequest({ application, roleName, description, permissions }, options = {}) {
  return request(`${API_BASE_URL}/v1/roles`, {
    method: 'PUT',
    body: {
      application,
      role: pascalCase(roleName),
      description,
      display_name: capitalizeValue(roleName),
      permissions,
    },
    ...options,
  });
}

export function editRoleRequest({ id, application, roleName, description, permissions }, options = {}) {
  return request(`${API_BASE_URL}/v1/roles`, {
    method: 'PUT',
    body: {
      id,
      application,
      role: pascalCase(roleName),
      description,
      display_name: capitalizeValue(roleName),
      permissions,
    },
    ...options,
  });
}

export function deleteRoleRequest(id, options = {}) {
  return request(`${API_BASE_URL}/v1/roles/${id}`, {
    method: 'DELETE',
    ...options,
  });
}

export function createSectionRequest({ application, section }, options = {}) {
  return request(`${API_BASE_URL}/v1/sections`, {
    method: 'PUT',
    body: {
      application,
      section,
    },
    ...options,
  });
}

export function editSectionRequest({ id, application, section }, options = {}) {
  return request(`${API_BASE_URL}/v1/sections`, {
    method: 'PUT',
    body: {
      id,
      application,
      section,
    },
    ...options,
  });
}

export function deleteSectionRequest({ id }, options = {}) {
  return request(`${API_BASE_URL}/v1/sections/${id}`, {
    method: 'DELETE',
    ...options,
  });
}

export function createPermissionRequest({ application, section, permission, description, endpoints }, options = {}) {
  return request(`${API_BASE_URL}/v1/permissions`, {
    method: 'PUT',
    body: {
      application,
      section,
      permission,
      description,
      endpoints: endpoints.length > 0 ? endpoints : [''],
    },
    ...options,
  });
}

export function editPermissionRequest({ id, application, section, permission, description, endpoints }, options = {}) {
  return request(`${API_BASE_URL}/v1/permissions`, {
    method: 'PUT',
    body: {
      id,
      application,
      section,
      permission,
      description,
      endpoints: endpoints.length > 0 ? endpoints : [''],
    },
    ...options,
  });
}

export function deletePermissionRequest({ id }, options = {}) {
  return request(`${API_BASE_URL}/v1/permissions/${id}`, {
    method: 'DELETE',
    ...options,
  });
}

export function getUserRoleRequest({ employeeNumber, email, role, assignedBy, startDate, endDate }, options = {}) {
  const query = queryString.stringify({
    username: employeeNumber,
    email,
    role,
    assigned_by: assignedBy?.length > 0 ? assignedBy : undefined,
    start_creation_date: startDate,
    end_creation_date: endDate,
  });

  return request(`${API_BASE_URL}/v1/users/profile?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function editUserRoleRequest(
  { id, role, username, email, application, assignedBy, creationDate },
  options = {}
) {
  return request(`${API_BASE_URL}/v1/users`, {
    method: 'PUT',
    body: {
      id,
      role,
      username,
      email,
      application,
      assigned_by: assignedBy,
      creation_date: creationDate || getCurrentDateUtc(),
      modified_date: getCurrentDateUtc(),
    },
    ...options,
  });
}

export function getApplicationsRequest(options = {}) {
  return request(`${API_BASE_URL}/v1/applications`, {
    method: 'GET',
    ...options,
  });
}

export function createApplicationRequest({ application }, options = {}) {
  return request(`${API_BASE_URL}/v1/applications`, {
    method: 'PUT',
    body: {
      application: pascalCase(application),
    },
    ...options,
  });
}

export function editApplicationRequest({ id, application }, options = {}) {
  return request(`${API_BASE_URL}/v1/applications`, {
    method: 'PUT',
    body: {
      id,
      application: pascalCase(application),
    },
    ...options,
  });
}

export function deleteApplicationRequest(id, options = {}) {
  return request(`${API_BASE_URL}/v1/applications/${id}`, {
    method: 'DELETE',
    ...options,
  });
}
