import useFeature from './hooks/useFeature';
import PropTypes from 'prop-types';

function RenderFeature( props ) {
  const { name, children, render = children } = props;
  const hasFeature = useFeature(name);

  if (typeof render === 'function') return render(hasFeature);

  return hasFeature ? render : null;
}

RenderFeature.propTypes = {
  name: PropTypes.object,
  children: PropTypes.object,
  render: PropTypes.object
}
export default RenderFeature;