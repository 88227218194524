import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import reducer, { initialState } from './reducer';

export const ApplicationContext = createContext();

function AccessManagementApplication({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ApplicationContext.Provider value={{ state, dispatch }}>
      {React.Children.only(children)}
    </ApplicationContext.Provider>
  );
}

AccessManagementApplication.propTypes = {
  children: PropTypes.node,
};

export default AccessManagementApplication;
