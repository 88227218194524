import React from 'react';
import PropTypes from 'prop-types';

import { MdInfoOutline } from 'react-icons/md';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@gbm/queen-ui-guidelines';

import styles from './styles.module.scss';

export function ApplicationDeleteConfirmation({ isOpen, onClose, onConfirm, loading, error, application }) {
  return (
    <Dialog open={isOpen} id="dialogDeleteAppConfirmation" onClose={onClose}>
      <DialogTitle>Delete application</DialogTitle>

      <DialogContent>
        <DialogContentText className={styles.unselectable}>
          <Typography>
            Are you sure that you want to delete the application <b>{application.application}</b>?
          </Typography>
          <div className={styles.warning}>
            <MdInfoOutline className={styles.icon} />
            <Typography>This action will remove the access of the application</Typography>
          </div>
        </DialogContentText>
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button id="btnAbortDeleteApp" disabled={loading} onClick={onClose} color="light">
          Cancel
        </Button>

        <Button id="btnAcceptConfirmationDeleteApp" disabled={loading} loading={loading} onClick={onConfirm} autoFocus>
          {error ? 'Retry' : 'Accept'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ApplicationDeleteConfirmation.propTypes = {
  error: PropTypes.bool,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  application: PropTypes.object,
};
