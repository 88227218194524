import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import reducer, { initialState } from './reducer';

export const PermissionContext = createContext();

function AccessManagementPermission({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PermissionContext.Provider value={{ state, dispatch }}>{React.Children.only(children)}</PermissionContext.Provider>
  );
}

AccessManagementPermission.propTypes = {
  children: PropTypes.node,
};

export default AccessManagementPermission;
