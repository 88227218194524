import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import reducer, { initialState } from './reducer';

export const SectionContext = createContext();

function AccessManagementSection({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <SectionContext.Provider value={{ state, dispatch }}>{React.Children.only(children)}</SectionContext.Provider>;
}

AccessManagementSection.propTypes = {
  children: PropTypes.node,
};

export default AccessManagementSection;
