import moment from 'moment-timezone';
import lightFormat from 'date-fns/lightFormat';
import { isInclusivelyAfterDay } from 'react-dates';

const dateFormat = 'YYYY-MM-DD';
const maxDateToRangeDates = moment().add(0, 'months').add(1, 'day');

const isOutsideRangeInclusivelyAfterDay = day => isInclusivelyAfterDay(day, maxDateToRangeDates);

export const rangeDatesConfig = {
  dateFormat,
  maxDateToRangeDates,
  isOutsideRangeInclusivelyAfterDay,
};

export function getCurrentDateUtc() {
  return lightFormat(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
}

export function getCurrentDate() {
  return lightFormat(new Date(), "yyyy-MM-dd'T'HH:mm");
}

export function getFormatDateTime(value) {
  const dateObj = new Date(value);

  // Get UTC components
  const day = dateObj.getUTCDate();
  const month = dateObj.getUTCMonth() + 1;
  const year = dateObj.getUTCFullYear();
  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();

  // Format the components into 'dd/MM/yyyy HH:mm:ss' format
  const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year} ${hours
    .toString()
    .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

  return formattedDate;
}
