import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import reducer, { initialState } from './reducer';

export const UserRoleContext = createContext();

function AccessManagementUserRole({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserRoleContext.Provider value={{ state, dispatch }}>{React.Children.only(children)}</UserRoleContext.Provider>
  );
}

AccessManagementUserRole.propTypes = {
  children: PropTypes.node,
};

export default AccessManagementUserRole;
