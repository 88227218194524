import produce from 'immer';

import { getUserRole, editUserRole } from './actions';

export const initialState = {
  userRole: {
    loading: false,
    loaded: false,
    error: null,
    list: [],
  },

  editUserRole: {
    loading: false,
    loaded: false,
    error: null,
  },
};

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getUserRole.REQUEST:
        draft.userRole.loading = true;
        draft.userRole.loaded = false;
        draft.userRole.error = null;
        draft.userRole.list = [];
        break;

      case getUserRole.SUCCESS:
        draft.userRole.loading = false;
        draft.userRole.loaded = true;
        draft.userRole.list = payload;
        break;

      case getUserRole.FAILURE:
        draft.userRole.loading = false;
        draft.userRole.loaded = false;
        draft.userRole.error = payload;
        break;

      case getUserRole.RESET:
        draft.userRole = { ...initialState.userRole };
        break;

      case editUserRole.REQUEST:
        draft.editUserRole.loading = true;
        draft.editUserRole.loaded = false;
        draft.editUserRole.error = null;
        break;

      case editUserRole.SUCCESS:
        draft.editUserRole.loading = false;
        draft.editUserRole.loaded = true;
        break;

      case editUserRole.FAILURE:
        draft.editUserRole.loading = false;
        draft.editUserRole.loaded = false;
        draft.editUserRole.error = payload;
        break;

      case editUserRole.RESET:
        draft.editUserRole = { ...initialState.editUserRole };
        break;

      default:
        return draft;
    }
  });

export default reducer;
