import useFeatures from './useFeatures';

export default function useFeature(name) {
  const features = useFeatures();

  if (Array.isArray(features)) return features.includes(name);

  if (typeof features[name] === 'boolean') return features[name];

  return name.split('/').reduce((featureGroup, featureName) => {
    if (typeof featureGroup === 'boolean') return featureGroup;

    if (featureGroup[featureName] === undefined) return false;

    return featureGroup[featureName];
  }, features);
}
