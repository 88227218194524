export const METHODS = [
  { value: 'POST', label: 'POST' },
  { value: 'PUT', label: 'PUT' },
  { value: 'PATCH', label: 'PATCH' },
  { value: 'DELETE', label: 'DELETE' },
];

export const LOCAL_STORAGE = {
  TOKEN: 'rbp_token',
  REFRESH_TOKEN: 'rbp_refresh_token',
  LAST_TIME_ACTIVE: 'rbp_last_time_active',
  ROLE_ID: 'rbp_role_id',
};
