import capitalize from 'lodash/capitalize';

export function removeMultipleSpaces(string) {
  return string?.replace(/  +/g, ' ').trim();
}

export function removeAccents(string) {
  return string?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function capitalizeAll(string) {
  if (string) {
    const words = string.split(' ');
    const reducer = (capitalizeString, word) => `${capitalizeString} ${capitalize(word)}`;
    return words.reduce(reducer, '').trim();
  }

  return;
}

export function pascalCase(string) {
  if (string) {
    return removeAccents(capitalizeAll(string).replace(/ /g, ''));
  }

  return;
}

export function capitalizeValue(string) {
  if (string) {
    return capitalize(removeMultipleSpaces(string));
  }

  return;
}

export function removeSpacesValueField({ target }) {
  const cursorPosition = target.selectionEnd;
  target.value = target.value.replace(/ /g, '');
  cursorPosition && target.setSelectionRange(cursorPosition, cursorPosition);
  return target.value;
}

export function lowerCaseValueField({ target }, removeSpaces = false) {
  const cursorPosition = target.selectionEnd;
  target.value = target.value.toLowerCase();
  removeSpaces && (target.value = target.value.replace(/ /g, ''));
  cursorPosition && target.setSelectionRange(cursorPosition, cursorPosition);
  return target.value;
}
