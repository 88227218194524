import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import { Button, Grid, Input, Margin, Modal, ModalHeader, ModalBody, ModalFooter } from '@gbm/queen-ui-guidelines';
import { applicationNameInput, isApplicationUsed } from 'utils/accessManagement';

export function ApplicationModal({ open, onClose, onSubmit, title, loading, error, applications, appToEdit }) {
  const { handleSubmit, register, errors, formState, reset } = useForm({ mode: 'onChange' });

  useEffect(() => {
    if (appToEdit) {
      const fillForm = async () => {
        await reset({ [applicationNameInput.name]: appToEdit.application });
      };

      fillForm();
    }
  }, [appToEdit, reset]);

  return (
    <Modal id="modalHandleApp" isOpen={open} size="md" scrollable centered>
      <ModalHeader onClose={onClose}>{title}</ModalHeader>
      <ModalBody>
        <Grid container>
          <Grid row>
            <Grid sm={12}>
              <Margin side="bottom" xs={36}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Input
                    {...applicationNameInput}
                    label={applicationNameInput.label}
                    placeholder={applicationNameInput.placeholder}
                    error={Boolean(errors[applicationNameInput.name])}
                    errorMsg={errors[applicationNameInput.name]?.message}
                    ref={register({
                      ...applicationNameInput.register,
                      validate: value =>
                        value === appToEdit?.application ||
                        !isApplicationUsed(value, applications) ||
                        'The application already exists. Try again with another name.',
                    })}
                  />
                </form>
              </Margin>
            </Grid>
          </Grid>
        </Grid>
      </ModalBody>

      <ModalFooter>
        <Button id="btnCancelApp" type="button" onClick={onClose} disabled={loading} color="light">
          Cancel
        </Button>

        <Button
          id="btnSubmitApp"
          type="button"
          disabled={!(formState.isValid && formState.isDirty) || loading}
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          {error ? 'Retry' : 'Accept'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ApplicationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  applications: PropTypes.array,
  appToEdit: PropTypes.object,
};
