import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { Icon } from '@gbm/queen-ui-guidelines';

import styles from './styles.module.scss';

function IconDelete({ className }) {
  return <Icon className={clsx(styles.delete, 'remixicon-delete-bin-line', className)} />;
}

IconDelete.propTypes = {
  className: PropTypes.string,
};

export default IconDelete;
