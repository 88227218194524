import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import reducer, { initialState } from './reducer';

export const RoleContext = createContext();

function AccessManagementRole({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <RoleContext.Provider value={{ state, dispatch }}>{React.Children.only(children)}</RoleContext.Provider>;
}

AccessManagementRole.propTypes = {
  children: PropTypes.node,
};

export default AccessManagementRole;
