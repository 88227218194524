import { createRoutine } from 'redux-saga-routines';
import extendRoutine from 'utils/routines';

import {
  GET_APPLICATIONS,
  CREATE_APPLICATION,
  EDIT_APPLICATION,
  DELETE_APPLICATION,
  SELECTED_APPLICATION,
} from './constants';

export const getApplications = createRoutine(GET_APPLICATIONS);
export const createApplication = extendRoutine(createRoutine(CREATE_APPLICATION), 'RESET');
export const editApplication = extendRoutine(createRoutine(EDIT_APPLICATION), 'RESET');
export const deleteApplication = extendRoutine(createRoutine(DELETE_APPLICATION), 'RESET');
export const selectedApplication = extendRoutine(createRoutine(SELECTED_APPLICATION), 'RESET');
