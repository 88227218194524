import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Input,
} from '@gbm/queen-ui-guidelines';

import styles from './styles.module.scss';

export function ApplicationDeleteDialog({ isOpen, onClose, onConfirm, application }) {
  const [isValidName, setIsValidName] = useState(false);

  const handleChangeInput = ({ target: { value } }) => {
    setIsValidName(value === application.application);
  };

  return (
    <Dialog open={isOpen} id="appDeleteSection">
      <DialogTitle>Delete application</DialogTitle>

      <form onSubmit={onConfirm}>
        <DialogContent>
          <DialogContentText className={styles.unselectable}>
            <Typography>
              To confirm, please enter <b>{application.application}</b>
            </Typography>
          </DialogContentText>
          <Input
            id="inputAppName"
            onChange={handleChangeInput}
            className={styles.inputName}
            onPaste={e => e.preventDefault()}
            autoComplete="off"
            autoFocus
          />
        </DialogContent>

        <DialogActions>
          <Button id="btnCancelDeleteApp" onClick={onClose} color="light">
            Cancel
          </Button>
          <Button type="submit" id="btnAcceptDeleteApp" disabled={!isValidName}>
            Accept
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

ApplicationDeleteDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  application: PropTypes.object,
};
